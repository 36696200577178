import _ from 'lodash';

/**
 * creer un clone du formulaire pour y faire des modifications de champs pour l'API
 * @param body
 * @returns {*}
 */
export const formatDataForApi = (body) => {
    let cloneBody = _.cloneDeep(body);

    stringToBool(cloneBody);
    stringToInt(cloneBody);
    dateToString(cloneBody);
    removePermisNull(cloneBody);
    removeSpace(cloneBody);
    removeHyphen(cloneBody);
    reformatGarantiesSelected(cloneBody);
    cleanListePersonnes(cloneBody);
    //dateHourToString(cloneBody);
    unsetFields(cloneBody);
    reformatHamon(cloneBody);

    reIndexArray(cloneBody);

    return cloneBody;
};

/**
 * retire les espaces dans les champs
 * @param clone
 */
export const removeSpace = (clone) => {
    const fields = ['DemandeTarif[ListePersonnes][0][TelPortable]', 'DemandeContrat[SignaturePhone]'];

    _.forEach(fields, (f) => {
        _.get(clone, f) && _.set(clone, f, _.get(clone, f).replace(/\s/g, ''));
    });
};

/**
 * retire les tirets dans les champs
 * @param clone
 */
export const removeHyphen = (clone) => {
    const fields = ['DemandeTarif[Vehicule][Immatriculation]', 'DemandeContrat[IBAN]'];

    _.forEach(fields, (f) => {
        _.get(clone, f) && _.set(clone, f, _.replace(_.get(clone, f), /-/g, ''));
    });
};

/**
 * convertie les champs qui ont 'true' ou 'false' en boolean
 * @param clone
 */
export const stringToBool = (clone) => {
    const booleans = [
        'DemandeTarif[Antecedents][SuspensionPermis]',
        'DemandeTarif[Antecedents][ResiliationAssureur]',
        'DemandeTarif[Antecedents][AnnulationPermis]',
        'DemandeTarif[Antecedents][CondamnationAlcoolemie]',
        'DemandeTarif[Antecedents][CondamnationStupefiants]',
        'DemandeTarif[Antecedents][CondamnationDelitFuite]',
        'DemandeTarif[Antecedents][CondamnationConduiteSansAssurance]',
        'DemandeTarif[ListePersonnes][0][Souscripteur]',
        'DemandeTarif[ListePersonnes][0][ConducteurMineur]',
        'DemandeTarif[DevoirConseil][DevoirVol]',
        'DemandeTarif[DevoirConseil][DevoirDommage]',
        'DemandeTarif[DevoirConseil][DevoirASS2]',
        'DemandeTarif[DevoirConseil][DevoirASS3]',
        'DemandeTarif[DevoirConseil][DevoirACCESSEQP]',
        'DemandeTarif[DevoirConseil][DevoirRAFRA]',
        'DemandeContrat[DevisHamon]',
        'DemandeTarif[Vehicule][Assure3DerniersMois]',
        'DemandeTarif[DevisHamon]',
        'DemandeTarif[Vehicule][PossessionPlusDe3Mois]',
        'DemandeTarif[ListePersonnes][0][DejaClient]',
        'DemandeTarif[ListePersonnes][1][DejaClient]',
    ];

    const returnBool = (string) => {
        if (_.includes(['true', '1', true], string)) {
            return true;
        }
        if (_.includes(['false', '0', false], string)) {
            return false;
        }
        return null;
    };

    _.forEach(booleans, (b) => {
        _.get(clone, b) && _.set(clone, b, returnBool(_.get(clone, b)));
    });
};

/**
 * convertie les champs string en integer
 * @param clone
 */
export const stringToInt = (clone) => {
    const strings = [
        'DemandeTarif[DevoirConseil][DevoirIC]',
        'DemandeTarif[DevoirConseil][DevoirMontantACCESSEQP]',
        'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]',
        'DemandeTarif[ListePersonnes][0][ValeurCrmAuto]',
        'DemandeTarif[Vehicule][ValeurVehicule]',
        'DemandeTarif[ListePersonnes][0][NbMoisReferenceMoto]',
        'DemandeTarif[ListePersonnes][0][NbMoisReferenceAuto]',
        'DemandeTarif[ListePersonnes][1][IdSociety]',
    ];

    _.forEach(strings, (s) => {
        _.get(clone, s) && _.set(clone, s, Number(_.replace(_.get(clone, s), /\s/g, '')));
    });
};

/**
 * convertie les champs de date moment en string dd/mm/aaaa
 * @param clone
 */
export const dateToString = (clone) => {
    let dates = [
        'DemandeTarif[Vehicule][DateMEC]',
        'DemandeTarif[ListePersonnes][0][DateNaissance]',
        'DemandeTarif[ListePersonnes][0][ListePermis][0][DatePermis]',
        'DemandeTarif[ListePersonnes][0][ListePermis][1][DatePermis]',
        'DemandeTarif[ListePersonnes][0][ListePermis][2][DatePermis]',
        'DemandeTarif[ListePersonnes][0][ListePermis][3][DatePermis]',
        'DemandeTarif[ListePersonnes][0][ListePermis][4][DatePermis]',
        'DemandeTarif[Antecedents][ListeSinistres][0]DateSinistre]',
        'DemandeTarif[Antecedents][ListeSinistres][1]DateSinistre]',
        'DemandeTarif[Antecedents][ListeSinistres][2]DateSinistre]',
        'DemandeTarif[Antecedents][ListeSinistres][3]DateSinistre]',
        'DemandeTarif[Antecedents][ListeSinistres][4]DateSinistre]',
        'DemandeTarif[ListePersonnes][1][DateNaissance]',
        'DemandeTarif[Antecedents][DateAnnulationPermis]',
        'DemandeTarif[Antecedents][DateSuspensionPermis]',
        'DemandeTarif[Antecedents][DateResiliationAssureur]',
        'DemandeContrat[TiersPayeur][BirthDate]',
        'DemandeTarif[Vehicule][DateAchatVehicule]'
    ];

    _.forEach(dates, (d) => {
        _.get(clone, d) && _.get(clone, d)._isValid && _.set(clone, d, _.get(clone, d).format('L'));
    });
};

/**
 * Retire les permis qui ont un TypePermis à null
 * @param clone
 */
export const removePermisNull = (clone) => {
    const conducteur = clone.DemandeTarif.ListePersonnes;

    conducteur &&
        conducteur[0] &&
        _.remove(conducteur[0].ListePermis, (p) => {
            return p && (p.TypePermis === null || p.TypePermis === undefined);
        });
};

/**
 * Réindex les array qui ont un index undefined
 * @param clone
 */
export const reIndexArray = (clone) => {
    const arrays = ['DemandeTarif[ListePersonnes][0][ListePermis]', 'DemandeTarif[ListeOptions]'];

    _.forEach(arrays, (a) => {
        _.get(clone, a) &&
            _.set(
                clone,
                a,
                _.filter(_.get(clone, a), (item) => {
                    return item !== undefined && !_.isEmpty(item);
                }),
            );
    });
};

export const reformatGarantiesSelected = (clone) => {
    let ListeOptions = {};

    //options ex {ASS: "ASS3", KSM: "KSM", PCC: "PCC1"}
    if (clone.options && _.size(clone.options) > 0) {
        ListeOptions = _.map(
            _.filter(clone.options, (o) => o && o !== ''),
            (o) => {
                return {
                    CodeOption: o,
                    Souscrite: true,
                };
            },
        );
    }

    //Pour les options liste (ACCESSEQP) il faut passer l'option selectionnée
    // optionsListe ex {ACCESSEQP: "10000"}
    if (clone.optionsListe && _.size(clone.optionsListe) > 0) {
        const listeL = _.map(clone.optionsListe, (val, key) => {
            return {
                CodeOption: key,
                Souscrite: val !== undefined,
                DetailOption: val !== undefined ? val : null,
            };
        });
        if (listeL.length > 0) {
            ListeOptions = _.concat(ListeOptions, listeL);
        }
    }

    // optionsFranchise ex {RAFRADTA: false, RAFRAVI: true}
    if (clone.optionsFranchise && _.size(clone.optionsFranchise) > 0) {
        const listeF = _.map(clone.optionsFranchise, (val, key) => {
            return {
                CodeOption: key,
                Souscrite: val === true || val === 'true',
            };
        });

        if (listeF.length > 0) {
            ListeOptions = _.concat(ListeOptions, listeF);
        }
    }

    clone.DemandeTarif.ListeOptions = ListeOptions;
};

/**
 * On vide les personnes vides
 * Si une deuxième personne est définie c'est que cette personne est souscripteur non conducteur (tuteur ou société)
 * On duplique les informations de la personne 0 => 1
 * @param clone
 */
export const cleanListePersonnes = (clone) => {
    const fieldToCopy = ['Adresse1', 'Adresse2', 'Adresse3', 'CP', 'Ville', 'TelPortable', 'TelFixe', 'Email'];
    clone.DemandeTarif.ListePersonnes[0].Souscripteur = true;

    _.remove(clone.DemandeTarif.ListePersonnes, (p) => {
        return p === undefined || p === null;
    });

    if (clone.DemandeTarif.ListePersonnes[1]) {
        //Si déjà client avec souscripteur
        if(_.includes(['true', '1', true], clone.DemandeTarif.ListePersonnes[1].DejaClient) || _.includes(['true', '1', true], clone.DemandeTarif.ListePersonnes[0].DejaClient)){
            clone.DemandeTarif.ListePersonnes[0].DejaClient = clone.DemandeTarif.ListePersonnes[1].DejaClient = true;
        }
        if(clone.DemandeTarif.ListePersonnes[1].NumeroClient){
            clone.DemandeTarif.ListePersonnes[0].NumeroClient = clone.DemandeTarif.ListePersonnes[1].NumeroClient;
        } else if(clone.DemandeTarif.ListePersonnes[0].NumeroClient){
            clone.DemandeTarif.ListePersonnes[1].NumeroClient = clone.DemandeTarif.ListePersonnes[0].NumeroClient;
        }
        clone.DemandeTarif.ListePersonnes[0].Souscripteur = false;
        clone.DemandeTarif.ListePersonnes[1].Souscripteur = true;
        clone.DemandeTarif.ListePersonnes[1].RoleConducteur = 'N';

        _.forEach(fieldToCopy, (f) => {
            if (clone.DemandeTarif.ListePersonnes[0][f]) {
                clone.DemandeTarif.ListePersonnes[1][f] = clone.DemandeTarif.ListePersonnes[0][f];
            }
        });
    }
};
/**
 * convertie les champs de date moment en string dd/mm/aaaa hh:mm
 * @param clone
 */
export const dateHourToString = (clone) => {
    const dates = ['DemandeContrat[DateHeureEffet]'];

    _.forEach(dates, (d) => {
        _.get(clone, d) && _.set(clone, d, _.get(clone, d));
    });
};

/**
 * supprime un field du formulaire si la value est null, undefined, vide
 * @param clone
 */
export const unsetFields = (clone) => {
    const fields = ['DemandeTarif[ListePersonnes][0][NumeroClient]'];

    _.forEach(fields, (f) => {
        (_.get(clone, f) === null || _.get(clone, f) === undefined || _.get(clone, f) === '') && _.unset(clone, f);
    });
};

/**
 * convertie les champs de date moment en string dd/mm/aaaa hh:mm
 * @param clone
 */
export const reformatHamon = (clone) => {
    if (clone.DemandeContrat) {
        let assureurOld = null;
        if (
            (clone.DemandeContrat.DevisHamon === true || clone.DemandeContrat.DevisHamon === '1') &&
            clone.DemandeContrat.DevisHamonAssureurActuel
        ) {
            assureurOld = _.split(clone.DemandeContrat.DevisHamonAssureurActuel, '|', 2);
        } else {
            clone.DemandeContrat.DevisHamonContratActuel = null;
        }

        clone.DemandeContrat.DevisHamonAssureurActuelId = assureurOld ? assureurOld[0] : null;
        clone.DemandeContrat.DevisHamonAssureurActuelName = assureurOld ? assureurOld[1] : null;
    }
};
