import _ from "lodash";
import {normalizeTel} from "./normalize.js";
import moment from "moment";
import {change} from "redux-form";
import {getDejaClientFieldStatus} from "@amo/core/utils/dejaClientFields.js";

const determineValueToComplete = (path, situationConducteur, dejaclientValues, conducteurDejaClient, souscripteurNonConducteurDejaClient) => {
    if (path.includes("DejaClientAntecedents")) return _.get(dejaclientValues, path);
    if (path.startsWith("DemandeTarif.ListePersonnes.1")) {
        // On regarde si dans le déjà client j'avais souscripteur != de conducteur
        return souscripteurNonConducteurDejaClient
            ? _.get(souscripteurNonConducteurDejaClient, path.replace("DemandeTarif.ListePersonnes.1.", ''))
            : _.get(conducteurDejaClient, path.replace("DemandeTarif.ListePersonnes.1.", ''));
    }
    if (path.startsWith("DemandeTarif.ListePersonnes.0")) {
        // On regarde si dans le déjà client si souscripteur != de conducteur dans le cas ou dans le formulaire j'ai souscripteur = conducteur
        return situationConducteur === 0
            ? _.get(souscripteurNonConducteurDejaClient || conducteurDejaClient, path.replace("DemandeTarif.ListePersonnes.0.", ''))
            : _.get(conducteurDejaClient, path.replace("DemandeTarif.ListePersonnes.0.", ''));
    }
    return _.get(dejaclientValues, path);
};

const updateListePermis = (formName, permis, nextAction) => {
    const typeMapping = { 'A': 3, 'B': 4, 'A1': 1, 'A2': 2, 'AM': 0 };
    const index = typeMapping[permis.TypePermis];

    if (index !== undefined) {
        nextAction.unshift(change(formName, `DemandeTarif.ListePersonnes.0.ListePermis.${index}.DatePermis`, moment(permis.DatePermis, "DD/MM/YYYY")));
        nextAction.unshift(change(formName, `DemandeTarif.ListePersonnes.0.ListePermis.${index}.TypePermis`, permis.TypePermis));
    }
};

export const dejaClient = (formName, updateDateNaissance = false, typeProduit = 'moto', dejaclientValues, nextAction, ListePersonnes = null) => {
    //Si j'ai des infos souscripteur c'est que je suis dans un cas mineur majeur ou société
    const conducteur = _.find(ListePersonnes, {'TypePersonne': 'P', 'RoleConducteur': 'P'});
    const souscripteurNonConducteur = _.find(ListePersonnes, (p) => {return (p?.TypePersonne === 'P' || p?.TypePersonne === 'M') && p?.RoleConducteur !== 'P'});

    const conducteurDejaClient = _.find(dejaclientValues.DemandeTarif.ListePersonnes, {'TypePersonne': 'P', 'RoleConducteur': 'P'});
    const souscripteurNonConducteurDejaClient = _.find(dejaclientValues.DemandeTarif.ListePersonnes, (p) => {return (p?.TypePersonne === 'P' || p?.TypePersonne === 'M') && p?.RoleConducteur !== 'P' });

    //On attend que la date de naissance du nouveau conducteurDejaClient soit définie pour pouvoir faire les tests mineurs/société
    // On détermine si le nouveau conducteurDejaClient est le même que le souscripteur ou un nouveau
    // 0 = souscripteurNonConducteurDejaClient est conducteurDejaClient, 1 = nouveau conducteurDejaClient, 2 = ancien conducteurDejaClient toujours différent de souscripteurNonConducteurDejaClient
    const situationConducteur = !souscripteurNonConducteur
        ? 0
        : souscripteurNonConducteur && souscripteurNonConducteurDejaClient && !moment(conducteurDejaClient?.DateNaissance, 'DD/MM/YYYY').diff(moment(conducteur?.DateNaissance, 'DD/MM/YYYY'))
            ? 2
            : 1;

    nextAction.unshift(change(formName, `DemandeTarif.ListePersonnes.0.DejaClient`, 'true'))
    nextAction.unshift(change(formName, `DemandeTarif.ListePersonnes.0.NumeroClient`,dejaclientValues?.DemandeTarif?.ListePersonnes?.[0]?.NumeroClient))
    if(souscripteurNonConducteurDejaClient?.TypePersonne === 'M') {
        nextAction.unshift(change(formName, `titulaireCarteGrise`, '2'))

        if(!souscripteurNonConducteur) {
            nextAction.unshift(change(formName, `DemandeTarif.ListePersonnes.1.TypePersonne`, 'M'))
            nextAction.unshift(change(formName, `DemandeTarif.ListePersonnes.1.Civilite`, '3'))
        }
    }
    if(situationConducteur > 0) {
        nextAction.unshift(change(formName, `DemandeTarif.ListePersonnes.0.NumeroClient`,dejaclientValues?.DemandeTarif?.ListePersonnes?.[1]?.NumeroClient))
    }

    // On récupère la liste des champs à compléter ou à vider en fonction du nouveau type de véhicule et de la situation du conducteur
    const fieldsToCompleteOrToEmpty = getDejaClientFieldStatus(_.toLower(typeProduit),  _.toLower(dejaclientValues?.DemandeTarif?.Vehicule?.typeProduit),  situationConducteur);

    //On vide les champs nécessaires
    _.forEach(fieldsToCompleteOrToEmpty.toEmpty, path => {
        if (updateDateNaissance || path !== "DemandeTarif.ListePersonnes.0.DateNaissance") {
            nextAction.unshift(change(formName, path, null));
        }
    });

    _.forEach(fieldsToCompleteOrToEmpty.toComplete, (path) => {
        let valueToComplete = determineValueToComplete(path, situationConducteur, dejaclientValues, conducteurDejaClient, souscripteurNonConducteurDejaClient);

        if(valueToComplete) {
            const lastPart = path.split('.').pop();
            switch (lastPart) {
                case "ListePermis":
                    _.forEach(valueToComplete, permis => updateListePermis(formName, permis, nextAction));
                    break;
                case "DateNaissance":
                    if (updateDateNaissance || path !== "DemandeTarif.ListePersonnes.0.DateNaissance") {
                        nextAction.unshift(change(formName, path, moment(valueToComplete, 'DD/MM/YYYY')));
                    }
                    break;
                case "DejaClientAntecedents":
                    nextAction.unshift(change(formName, path, valueToComplete));
                    break;
                case "NbMoisAssuranceAuto":
                case "NbMoisAssuranceMoto":
                    const isMoto = lastPart === "NbMoisAssuranceMoto";
                    nextAction.unshift(change(formName, isMoto ? `assure_moto` : `assure_auto`, valueToComplete > 0 ? "1" : "0"));
                    nextAction.unshift(change(formName, path, String(Number(valueToComplete).toFixed(0))));
                    break;
                case "TelFixe":
                    if(!determineValueToComplete(`DemandeTarif.ListePersonnes.0.TelPortable`, situationConducteur, dejaclientValues, conducteurDejaClient, souscripteurNonConducteurDejaClient)) {
                        nextAction.unshift(change(formName, `DemandeTarif.ListePersonnes.0.TelPortable`, String(normalizeTel(valueToComplete))));
                    }
                    break;
                case "TelPortable":
                    nextAction.unshift(change(formName, path, String(normalizeTel(valueToComplete))));
                    break;
                case "CodePostalGarage":
                    nextAction.unshift(change(formName, path, valueToComplete));
                    nextAction.unshift(change(formName, `postalCodeCity-lieuStationnement`, true));
                    break;
                case "Adresse1"://Pour la televente et le courtage
                case "Adresse2":
                case "Adresse3":
                case "CP":
                case "Ville":
                    const adresseValue = typeof valueToComplete === "number" && !Number.isInteger(valueToComplete) ? valueToComplete.toFixed(2) : String(valueToComplete);
                    nextAction.unshift(change(formName, path, adresseValue));
                    nextAction.unshift(change(formName, `DejaClient.${lastPart}`, adresseValue));

                    if(lastPart === "Ville") {
                        nextAction.unshift(change(formName, `postalCodeCity-dejaclientcodepostal`, `${valueToComplete} ${determineValueToComplete(`DemandeTarif.ListePersonnes.0.CP`, situationConducteur, dejaclientValues, conducteurDejaClient, souscripteurNonConducteurDejaClient)}`));
                    }
                    break;
                default:
                    nextAction.unshift(change(formName, path, typeof valueToComplete === "number" && !Number.isInteger(valueToComplete) ? valueToComplete.toFixed(2) : String(valueToComplete)));
                    break;
            }
        }
    });
    return nextAction;
}