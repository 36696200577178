import {API_URL_WS_FORMULAIRE } from '@amo/core/constants/constants.js';
import { encrypt } from '@amo/core/utils/functions';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { change, getFormSyncErrors, getFormValues, reduxForm } from 'redux-form';
import { POST_DEMANDE_RAPPEL } from '../../../redux/actions/app/demandeRappel/demandeRappel.actions';
import { postFill } from '../../../redux/actions/app/fill/fill.actions';
import { SAVE_DEVIS, postSaveDevis } from '../../../redux/actions/app/saveDevis/saveDevis.actions';
import { setIndexQuestion, setStep } from '../../../redux/actions/app/ui/ui.actions';
import {setLocalStorage} from '../../../redux/actions/core/localStorage/localStorage.actions';
import {
    getApporteur1Init,
    getCanalDemandeTarifInit, getDejaClientPersonnePrincipaleDemandeTarif,
    getDejaClientSouscripteurDemandeTarif,
    getFormuleChoisiePoliceDemandeTarifInit,
    getListesPersonnesODemandeTarifInit,
    getLinks,
    getNameWhitelabel,
    getNomSouscripteurDemandeTarif, getNumeroClientPersonnePrincipaleDemandeTarif,
    getNumeroClientSouscripteurDemandeTarif,
    getPoliceContratInit,
    getQuestionInit,
    getState,
    getStepInit,
    getToken
} from '../../../redux/selectors/init/init.selectors';
import {
    getErrorEntity,
    getIndexQuestion,
    getLoadingEntity,
    getStep,
    getUtmSource,
} from '../../../redux/selectors/ui/ui.selectors';
import { getVehicule } from '../../../redux/selectors/vehicule/vehicule.selectors';
import {getStepBySlug, getTitleByStep} from '../../../utils/function';
import Init from './Init';
import { InitialValues } from './InitialValues';
import formatDataBeforeStringify from './formatDataBeforeStringify';
import {analyticsSetEvent} from "../../../redux/actions/core/analytics/analytics.actions.js";
import {getInfoCodePromo} from "../../../redux/selectors/tarif/tarif.selectors.js";
import _ from "lodash";
import {loader} from "@amo/core/components/loaders/index.js";
import {
    dejaClientRefresh,
    POST_DEJA_CLIENT,
    postDejaClient
} from "../../../redux/actions/app/dejaClient/dejaClient.actions.js";
import {getDejaClient, getListePersonnes0DejaClient} from "../../../redux/selectors/dejaClient/dejaClient.selectors.js";
import {setVehicule} from "../../../redux/actions/app/vehicule/vehicule.actions.js";

const mapStateToProps = (state) => {
    const formValues = getFormValues('devis-direct')(state)
    const numeroClient = getNumeroClientSouscripteurDemandeTarif(state) || getNumeroClientPersonnePrincipaleDemandeTarif(state)
    const dejaClient = getDejaClientSouscripteurDemandeTarif(state) || getDejaClientPersonnePrincipaleDemandeTarif(state)
    return {
        step: getStep(state),
        personne0Init: getListesPersonnesODemandeTarifInit(state),
        indexQuestion: getIndexQuestion(state),
        stepInit: getStepInit(state),
        questionInit: getQuestionInit(state),
        data: formValues,
        errorsForm: getFormSyncErrors('devis-direct')(state),
        queryFill: `${API_URL_WS_FORMULAIRE}/fill/${getToken(state)}`,
        queryTarif: `${API_URL_WS_FORMULAIRE}/get_tarif/${getToken(state)}`,
        queryDejaClient: `${API_URL_WS_FORMULAIRE}/fill/${getToken(state)}`,
        saveDevisLoading: getLoadingEntity(state, SAVE_DEVIS),
        demandeRappelLoading: getLoadingEntity(state, POST_DEMANDE_RAPPEL),
        saveDevisError: getErrorEntity(state, SAVE_DEVIS),
        isDevisOrange: getState(state) === 'devis_orange',
        vehicule: getVehicule(state),
        initialValues: InitialValues(state),
        nameWhitelabel: getNameWhitelabel(state),
        utm_source: getUtmSource(state),
        token: getToken(state),
        canal: getCanalDemandeTarifInit(state),
        apporteur1: getApporteur1Init(state),
        formuleByDefault: getFormuleChoisiePoliceDemandeTarifInit(state),
        codePromo: getInfoCodePromo(state),
        numeroContrat: getPoliceContratInit(state),
        dejaClient: dejaClient,
        dejaClientData: getDejaClient(state),
        numeroClient: numeroClient,
        nomSouscripteur: getNomSouscripteurDemandeTarif(state),
        dejaClientLP0: getListePersonnes0DejaClient(state),
        loaded: (!dejaClient || !numeroClient || getLoadingEntity(state, POST_DEJA_CLIENT) === false),
        links: getLinks(state),
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            postFill,
            setIndexQuestion,
            setStep,
            setLocalStorage,
            postSaveDevis,
            changeValue: (field, value) => change('devis-direct', field, value),
            analyticsSetEvent,
            postDejaClient,
            dejaClientRefresh,
            setVehicule: data => setVehicule({data})
        },
        dispatch,
    );

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { data, indexQuestion, step, queryFill, queryTarif, queryDejaClient, numeroClient, nomSouscripteur, dejaClientData, dejaClient } = stateProps;
    const { postFill, setIndexQuestion, setStep, setLocalStorage, changeValue, postDejaClient, dejaClientRefresh, analyticsSetEvent } = dispatchProps;
    const newDatas = {typeProduit: data?.typeVehicule,listePersonnes: data?.DemandeTarif?.ListePersonnes,shouldUpdate: false}; //shouldUpdate= true que pour le direct car le déjà cli est transparent et toujour au reload

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        nextQuestion: (delais) => {
            let storage = encrypt(
                JSON.stringify({ step, question: indexQuestion, data: formatDataBeforeStringify(data) }),
            );
            setLocalStorage({ entity: 'NEXT_QUESTION', storage, name: 'estimate' });
            if (indexQuestion < document.getElementsByClassName('question').length - 1) {
                if (delais) {
                    setTimeout(() => {
                        setIndexQuestion({ index: indexQuestion + 1 });
                    }, delais);
                } else {
                    setIndexQuestion({ index: indexQuestion + 1 });
                }
            } else {
                setStep({ step: step + 1 });
                setIndexQuestion({ index: 0 });
            }
            window.scroll(0, 0);
        },
        previousQuestion: (index = 1) => {
            /* reset la question en cours si click sur le btn retour uniquement avant l'étape tarif */
            if (step < getStepBySlug('votre-tarif')) {
                const inputs = document.getElementsByClassName('question')[indexQuestion].getElementsByTagName('input');
                for (let i = 0; i < inputs.length; i++) {
                    changeValue(inputs[i].getAttribute('name'), null);
                }
                //changeValue(document.getElementsByClassName('question')[indexQuestion].getElementsByTagName('input')[0]?.getAttribute('name'), '')
            }
            analyticsSetEvent({event: 'previousQuestion', datas: {
                    'previousQuestion' : {
                        'currentStep' : step < getStepBySlug('votre-tarif') ? document.getElementsByClassName('fade-in')[0]?.getElementsByClassName('question-title')[0]?.textContent : getTitleByStep(step),
                    }
                }
            });

            if (indexQuestion > 0) {
                setIndexQuestion({ index: indexQuestion - index });
            } else {
                setStep({ step: step - index });
                setTimeout(() => {
                    setIndexQuestion({ index: document.getElementsByClassName('question').length - index });
                }, 15);
            }
            window.scroll(0, 0);
        },
        goTo: ({ step, question }) => {
            setStep({ step });
            setIndexQuestion({ index: question });
        },
        postFill: ({ delais, getTarif, codePromo }) => {
            let storage = encrypt(JSON.stringify({ step, question: indexQuestion, data: data }));
            setLocalStorage({ entity: 'NEXT_QUESTION', storage, name: 'estimate' });

            if (delais) {
                setTimeout(() => {
                    postFill({ step, form: data, query: queryFill, queryTarif, getTarif, codePromo });
                }, delais);
            } else {
                postFill({ step, form: data, query: queryFill, queryTarif, getTarif, codePromo });
            }
        },
        dejaClientRefresh: (dataOverride) => !_.isEmpty(dejaClientData) ? dejaClientRefresh({data: {typeProduit: data?.typeVehicule, listePersonnes: data?.DemandeTarif?.ListePersonnes, ...dataOverride}, dejaClientData: dejaClientData}) : {},
        load: () => !!(dejaClient && _.isEmpty(dejaClientData) && numeroClient) ? postDejaClient({idDejaClient: numeroClient, nom: nomSouscripteur, query: queryDejaClient, otherData: newDatas}) : {},
    };
};

const InitContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'devis-direct',
    }),
    loader()
)(Init);

export default InitContainer;
