import React from 'react';
import _ from "lodash";
import { getAddress } from '@amo/core/utils/requestsApi';

const BoutonsAdresseDejaClient = (props) => {
    const {data, changeValue, handleError,hasSearchAddress, errorsForm} = props

    const postAdresseDejaClient = () => {
        let fields = [
            `DejaClient[Adresse1]`,
            `DejaClient[CP]`,
            `DejaClient[Ville]`,
        ];

        fields.forEach((field) => {
            if (!_.get(data, field) && field !== '') {
                handleError(field);
            } else {
                handleError(field, true);
            }
        });

        if (!_.some(['Adresse1', 'CP', 'Ville'], (field) => errorsForm?.DejaClient?.[field])) {
            if(hasSearchAddress) {
                const address = _.get(data, 'DejaClient[Adresse1]');
                getAddress(address, 'ValidateForm');
            }
            changeValue('DejaClient.Validate', true);
            changeValue('DemandeTarif[ListePersonnes][0][Adresse1]', _.get(data, 'DejaClient[Adresse1]'));
            changeValue('DemandeTarif[ListePersonnes][0][Adresse2]', _.get(data, 'DejaClient[Adresse2]'));
            changeValue('DemandeTarif[ListePersonnes][0][Adresse3]', _.get(data, 'DejaClient[Adresse3]'));
            changeValue('DemandeTarif[ListePersonnes][0][CP]', _.get(data, 'DejaClient[CP]'));
            changeValue('DemandeTarif[ListePersonnes][0][Ville]', _.get(data, 'DejaClient[Ville]'));
        } else {
            document.getElementsByClassName('input-postal')[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <div className="row justify-content-center">
            <div className="col-lg-8 col-md-6 col-sm-6 col-12 my-4">
                {_.get(data, 'DejaClient[Validate]') !== false ? (
                    <button
                        className="btn btn-primary btn-arrow"
                        onClick={() => changeValue('DejaClient.Validate', false)}
                    >
                        Modifier l'adresse postale
                    </button>
                ) : <>
                    <div className="alert-info alert mb-0 mt-2">
                        Cette nouvelle adresse postale sera utilisée sur l'ensemble de vos contrats.
                    </div>
                    <button
                    className="btn btn-primary btn-arrow mt-4"
                        onClick={() => postAdresseDejaClient()}
                    >
                        Je valide ma nouvelle adresse
                    </button>
                </>}
            </div>
        </div>
    );
};

export default BoutonsAdresseDejaClient;