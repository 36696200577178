import info from '@amo/core/assets/images/icons/card.svg';
import { BulleAide } from '@amo/core/components/containers';
import { Radio, Text } from '@amo/core/components/forms';
import { maxDate30Days, minDateHourToday } from '@amo/core/utils/validateField';
import _ from 'lodash';
import moment from 'moment';
import { Field } from 'redux-form';

const DateHeureEffet = (props) => {
    const { formuleChoisie, fractionnementChoisi, changeValue, data } = props;

    return (
        <>
            <div className={'row justify-content-center'}>
                <h3 className={'f-16 text-center fw-bold mt-5'}>Quand souhaitez-vous faire débuter votre contrat ?</h3>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={`debutContrat`}
                        value={'1'}
                        label={'Maintenant'}
                        className={'small'}
                        onClick={() =>
                            changeValue(
                                'DemandeContrat[DateHeureEffet]',
                                moment().add(15, 'minutes').format('DD/MM/YYYY HH:mm'),
                            )
                        }
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={`debutContrat`}
                        value={'0'}
                        label={'Plus tard'}
                        className={'small'}
                        onClick={() =>
                            changeValue(
                                'DemandeContrat[DateHeureEffet]',
                                moment().add(1, 'days').startOf('day').format('DD/MM/YYYY HH:mm'),
                            )
                        }
                    />
                </div>
            </div>
            {_.get(data, 'debutContrat') && (
                <div className={'row justify-content-center'}>
                    <div className={'col-lg-8 col-md-6 col-sm-12 col-12 my-2'}>
                        <Field
                            name={`DemandeContrat[DateHeureEffet]`}
                            label={`Date d'effet de votre contrat`}
                            component={Text}
                            type={'text'}
                            typeFormat={'dateHour'}
                            placeholder={'Exemple : 22/03/2023 12:20'}
                            maxLength={16}
                            validate={[maxDate30Days, minDateHourToday]}
                            required
                        />
                    </div>
                </div>
            )}
            <BulleAide
                icon={info}
                title={'Un justificatif d\'assurance dès la validation du paiement'}
                text={`Vous allez payer <strong>${
                    fractionnementChoisi === 'M'
                        ? _.round(formuleChoisie.ComptantMensuelPromo, 2)
                        : _.round(formuleChoisie.ComptantAnnuelPromo, 2)
                }€ TTC</strong> par carte bancaire <b>correspondant ${
                    fractionnementChoisi === 'M'
                        ? formuleChoisie.NbMoisComptant === 3
                            ? 'à vos trois premières mensualités'
                            : 'à vos deux premières mensualités'
                        : 'à votre première année'
                }</b>. Une fois ce paiement effectué, cela va déclencher l’envoi d’un justificatif d'assurance. Votre véhicule sera intégré sous 72h au FVA (Fichier des Véhicules Assurés).`}
                className={'mt-0 col-lg-8 offset-lg-2 col-md-12'}
            />

            <div className={'row justify-content-center'}>
                <div className={'col-lg-8 col-md-6 col-sm-12 col-12 my-2 text-start'}>
                    {fractionnementChoisi === 'M' ? (
                        <small>
                            À la fin de
                            vos {formuleChoisie.NbMoisComptant === 3 ? 'trois premiers mois' : 'deux premiers mois'},
                            vous serez prélevé tous les mois de{' '}
                            {_.round(formuleChoisie.PrimeMensuellePromo, 2)}€ TTC.
                        </small>
                    ) : (
                        <small>
                            A la fin de votre première année, vous serez prélevé tous les ans de{' '}
                            {_.round(formuleChoisie.ComptantAnnuel, 2)}€ TTC.
                        </small>
                    )}
                </div>
            </div>
        </>
    );
};

export default DateHeureEffet;
