import Permis from '@amo/core/assets/images/AMO_Icons/Permis.svg';
import InformationsBleu from '@amo/core/assets/images/icons/informations-bleu.png';
import warning from '@amo/core/assets/images/icons/warning.svg';
import { BulleAide, Question } from '@amo/core/components/containers';
import { DateField, Radio } from '@amo/core/components/forms';
import { showModal } from '@amo/core/utils/functions';
import { maxDateToday, permisA, permisA1, permisA2, permisAM, permisB } from '@amo/core/utils/validateField';
import { validateLicenseByVehicule } from '@amo/core/utils/validateLicense';
import _ from 'lodash';
import moment from 'moment';
import { Field } from 'redux-form';
import permis from '../../../../../../assets/images/bulle/permis.png';

const ChoixPermis = (props) => {
    const { nextQuestion, data, changeValue, vehicule, errorsForm, goTo } = props;
    let text = '';

    if (
        !_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis][4][TypePermis]') &&
        Number(data?.cylindreeVehicule) > 125 &&
        _.includes(['moto', 'scooter'], data?.typeVehicule)
    ) {
        text = `<p>Seuls les permis délivrés <b>au sein de l'Union européenne</b> sont pris en compte.</p><br/><p>Votre <b>permis B</b> n'est pas renseigné, votre date d'obtention est prise en compte dans le <b>calcul de la prime</b>.</p> <p>Merci de le préciser pour bénéficier de notre <b>meilleur tarif</b>.</p>`;
    } else {
        text = `<p>Seuls les permis délivrés <b>au sein de l'Union européenne</b> sont pris en compte.</p>`;
    }

    const isNotValid = () => {
        let notValid = false;
        _.forEach(_.get(data, `DemandeTarif[ListePersonnes][0][ListePermis]`), function (k, v) {
            if (!_.isEmpty(k)) {
                if (k !== undefined && k.TypePermis !== undefined && k.TypePermis !== null) {
                    if (
                        !_.get(data, `DemandeTarif[ListePersonnes][0][ListePermis][${v}][DatePermis]`) ||
                        !moment(
                            _.get(data, `DemandeTarif[ListePersonnes][0][ListePermis][${v}][DatePermis]`),
                            'DD/MM/YYYY',
                            true,
                        ).isValid() ||
                        !_.isEmpty(_.get(errorsForm, `DemandeTarif[ListePersonnes][0][ListePermis][${v}][DatePermis]`))
                    ) {
                        notValid = true;
                    }
                }
            }
        });
        return notValid;
    };
    let validLicense = validateLicenseByVehicule({
        permis: _.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'),
        vehicule,
        dateNaissance: _.get(data, 'DemandeTarif[ListePersonnes][0][DateNaissance]'),
    });

    return (
        <Question icon={Permis} title={'Quels <b>permis</b> détient le <b>conducteur</b>&nbsp;?'}>
            <div className={'row justify-content-center mt-2'}>
                <p
                    className={'f-12 text-secondary mb-3'}
                    onClick={() =>
                        showModal({
                            imageUrl: permis,
                            html: `<p>Vos permis détenus et date de permis sont disponibles au <strong>verso de votre permis de conduire</strong></p>`,
                        })
                    }
                >
                    <img src={InformationsBleu} alt="?" className={'align-middle'} />{' '}
                    <span className={'pb-1 cursor-pointer underline-link'}>
                        Où trouver les permis en ma possession&nbsp;?
                    </span>
                </p>
            </div>

            <BulleAide icon={warning} title={'Attention !'} text={text} className={'col-lg-12 col-md-12 my-4'} />

            <div className={'row justify-content-start mt-2'}>
                {Number(data?.cylindreeVehicule) <= 50 && (
                    <div className={'col-lg-4 col-md-6 col-sm-12 col-12 my-2'}>
                        <div>
                            <Field
                                component={Radio}
                                type={'checkbox'}
                                name={'DemandeTarif[ListePersonnes][0][ListePermis][0][TypePermis]'}
                                value={'AM'}
                                label={'Permis AM ou BSR'}
                                className={'small'}
                                normalize={(v) => (v ? 'AM' : null)}
                                onChange={() => changeValue('noPermis', null)}
                            />
                            {_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis][0][TypePermis]') && (
                                <div className={'bloc_permis'}>
                                    <p className={'label-text mb-2'}>Date d'obtention </p>
                                    <Field
                                        name={`DemandeTarif[ListePersonnes][0][ListePermis][0][DatePermis]`}
                                        component={DateField}
                                        placeholder={'JJ/MM/AAAA'}
                                        validate={[maxDateToday, permisAM]}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {moment(_.get(data, 'DemandeTarif[ListePersonnes][0][DateNaissance]'), 'DD/MM/YYYY').add(16, 'years') <
                    moment() && (
                    <div className={'col-lg-4 col-md-6 col-sm-12 col-12 my-2'}>
                        <div>
                            <Field
                                component={Radio}
                                type={'checkbox'}
                                name={'DemandeTarif[ListePersonnes][0][ListePermis][1][TypePermis]'}
                                value={'A1'}
                                label={'Permis A1'}
                                className={'small'}
                                normalize={(v) => (v ? 'A1' : null)}
                                onChange={() => changeValue('noPermis', null)}
                            />
                            {_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis][1][TypePermis]') && (
                                <div className={'bloc_permis'}>
                                    <p className={'label-text mb-2'}>
                                        Date d'obtention
                                        <span
                                            className="d-inline-block btn-help ms-2"
                                            onClick={() =>
                                                showModal({
                                                    title: 'À partir de 16 ans, le permis A1/AL&nbsp;:',
                                                    html: "<p>permet de conduire des motos <b>jusqu'à 125 cm³</b> (15 cv maxi)</p><p>La carte grise porte la mention <strong>MTL</strong></p>",
                                                })
                                            }
                                        >
                                            ?
                                        </span>
                                    </p>
                                    <Field
                                        name={`DemandeTarif[ListePersonnes][0][ListePermis][1][DatePermis]`}
                                        component={DateField}
                                        placeholder={'JJ/MM/AAAA'}
                                        validate={[maxDateToday, permisA1]}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {moment(_.get(data, 'DemandeTarif[ListePersonnes][0][DateNaissance]'), 'DD/MM/YYYY').add(18, 'years') <
                    moment() && (
                    <div className={'col-lg-4 col-md-6 col-sm-12 col-12 my-2'}>
                        <div>
                            <Field
                                component={Radio}
                                type={'checkbox'}
                                name={'DemandeTarif[ListePersonnes][0][ListePermis][2][TypePermis]'}
                                value={'A2'}
                                label={'Permis A2'}
                                className={'small'}
                                normalize={(v) => (v ? 'A2' : null)}
                                onChange={() => changeValue('noPermis', null)}
                            />
                            {_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis][2][TypePermis]') && (
                                <div className={'bloc_permis'}>
                                    <p className={'label-text mb-2'}>
                                        Date d'obtention
                                        <span
                                            className="d-inline-block btn-help ms-2"
                                            onClick={() =>
                                                showModal({
                                                    title: 'À partir de 18 ans, le permis A2 permet de conduire&nbsp;:',
                                                    html: "<p>- une moto dont la puissance <b>n'excède pas 35 kilowatts (47,5 cv)</b> (P2 sur la carte grise)</p><p>- une moto dont le rapport puissance/poids <b>n'excède pas 0,2 kilowatts par kilogramme</b></p><p>- une moto dont la puissance maximum d'origine est de <b>70&nbsp;kilowatts&nbsp;(95 cv)</b></p><p>- un tricycle à moteur d'une puissance maximum de <b>15 kw</b>.</p>",
                                                })
                                            }
                                        >
                                            ?
                                        </span>
                                    </p>
                                    <Field
                                        name={`DemandeTarif[ListePersonnes][0][ListePermis][2][DatePermis]`}
                                        component={DateField}
                                        placeholder={'JJ/MM/AAAA'}
                                        validate={[maxDateToday, permisA2]}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {moment(_.get(data, 'DemandeTarif[ListePersonnes][0][DateNaissance]'), 'DD/MM/YYYY').add(18, 'years') <
                    moment() && (
                    <div className={'col-lg-4 col-md-6 col-sm-12 col-12 my-2'}>
                        <div>
                            <Field
                                component={Radio}
                                type={'checkbox'}
                                name={'DemandeTarif[ListePersonnes][0][ListePermis][3][TypePermis]'}
                                value={'A'}
                                label={'Permis A'}
                                className={'small'}
                                normalize={(v) => (v ? 'A' : null)}
                                onChange={() => changeValue('noPermis', null)}
                            />
                            {_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis][3][TypePermis]') && (
                                <div className={'bloc_permis'}>
                                    <p className={'label-text mb-2'}>
                                        Date d'obtention
                                        <span
                                            className="d-inline-block btn-help ms-2"
                                            onClick={() =>
                                                showModal({
                                                    title: 'À partir de 20 ans, le permis A&nbsp;:',
                                                    html: '<p>est accessible <b>après 2 ans de permis A2 + une formation de 7 heures</b></p><p>Il permet de conduire toutes les motos, ainsi que tous les tricycles à moteur quelle que soit leur puissance.</p>',
                                                })
                                            }
                                        >
                                            ?
                                        </span>
                                    </p>
                                    <Field
                                        name={`DemandeTarif[ListePersonnes][0][ListePermis][3][DatePermis]`}
                                        component={DateField}
                                        placeholder={'JJ/MM/AAAA'}
                                        validate={[maxDateToday, permisA]}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {moment(_.get(data, 'DemandeTarif[ListePersonnes][0][DateNaissance]'), 'DD/MM/YYYY').add(16, 'years') <
                    moment() && (
                        <div className={'col-lg-4 col-md-6 col-sm-12 col-12 my-2'}>
                            <div>
                                <Field
                                    component={Radio}
                                    type={'checkbox'}
                                    name={'DemandeTarif[ListePersonnes][0][ListePermis][4][TypePermis]'}
                                    value={'B'}
                                    label={'Permis B'}
                                    className={'small'}
                                    normalize={(v) => (v ? 'B' : null)}
                                    onChange={() => changeValue('noPermis', null)}
                                />
                                {_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis][4][TypePermis]') && (
                                    <div className={'bloc_permis'}>
                                        <p className={'label-text mb-2'}>
                                            Date d'obtention
                                            <span
                                                className="d-inline-block btn-help ms-2"
                                                onClick={() =>
                                                    showModal({
                                                        title: 'À partir de 18 ans, le permis B&nbsp;:',
                                                        html: "<div class=\"text-start\"><p><b>Autorise la conduite d’une moto jusqu'à 125cm3 à la double condition que&nbsp;:</b></p><br/><ul><li>le conducteur soit titulaire du permis B depuis au moins 2 ans</li><li>le conducteur ait suivi une formation pratique de 7 heures dispensée par un établissement ou une association agréés au titre de l'article L213-1 ou L213-7</li></ul><br/><p><b>Autorise la conduite d’un scooter 3 roues de la catégorie L5e avec un permis B dans les 3 conditions suivantes&nbsp;:</b></p><br/><ul><li>le conducteur soit titulaire du permis B depuis au moins 2 ans</li><li>le conducteur ait 21 ans ou plus</li><li>le conducteur ait suivi une formation pratique de 7 heures</li></ul><p><b>Dispense de formation dans les cas suivants&nbsp;:</b></p><br/><ul><li>le conducteur a obtenu le permis B (avec l'équivalence A1) avant mars 1980</li><li>le conducteur a conduit une 125cm³ ou un scooter 3 roues de la catégorie L5e entre 2006 et 2010</li><li>le conducteur a suivi la formation de 3 heures avant 2011</li></ul></div>",
                                                    })
                                                }
                                            >
                                            ?
                                        </span>
                                        </p>
                                        <Field
                                            name={`DemandeTarif[ListePersonnes][0][ListePermis][4][DatePermis]`}
                                            component={DateField}
                                            placeholder={'JJ/MM/AAAA'}
                                            validate={[maxDateToday, permisB]}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                {
                    /*
                        Les personnes nées à partir du 1er janvier 1988 doivent être titulaires du permis dans la catégorie "apprenti motard", AM, ex-BSR
                        Les personnes nées avant le 1er janvier 1988 sont dispensées de ces titres.
                    */
                    Number(data?.cylindreeVehicule) <= 50 &&
                    moment(_.get(data, 'DemandeTarif[ListePersonnes][0][DateNaissance]'), 'DD/MM/YYYY') <
                    moment('1988-01-01') && (
                        <div className={'col-lg-4 col-md-6 col-sm-12 col-12 my-2'}>
                            <div>
                                <Field
                                    component={Radio}
                                    type={'checkbox'}
                                    name={'noPermis'}
                                    value={'0'}
                                    label={"Je n'ai pas de permis"}
                                    className={'small'}
                                    normalize={(v) => (v ? '0' : null)}
                                    onChange={() =>
                                        data?.noPermis
                                            ? true
                                            : changeValue('DemandeTarif[ListePersonnes][0][ListePermis]', null)
                                    }
                                />
                            </div>
                        </div>
                    )
                }
            </div>

            {!validLicense?.status && (
                <>
                    {((!_.isEmpty(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]')) &&
                        _.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (p) => {
                            return p?.TypePermis;
                        })) ||
                        _.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis][2][TypePermis]')) && (
                        <div className="alert alert-danger mt-3" role="alert">
                            {!_.isEmpty(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]')) &&
                                _.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (p) => {
                                    return p?.TypePermis;
                                }) && (
                                    <p className={'mb-0'}>
                                        {validLicense?.message ||
                                            'Votre permis ne permet pas de conduire ce type de véhicule.'}
                                        <br />
                                        <span
                                            className={'btn btn-info btn-sm f-12 cursor-pointer mt-2 text-light'}
                                            onClick={() => goTo({ step: 0, question: 1 })}
                                        >
                                            modifier mon véhicule
                                        </span>
                                    </p>
                                )}

                            {!_.isEmpty(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]')) &&
                                _.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (p) => {
                                    return p?.TypePermis;
                                }) &&
                                _.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis][2][TypePermis]') && <hr />}

                            {_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis][2][TypePermis]') && (
                                <p className={'mb-0'}>
                                    {validLicense?.message ||
                                        'Si vous détenez uniquement le permis A2, vous devez sélectionner un véhicule compatible A2 (47.5 CV soit 35 KW).'}
                                </p>
                            )}
                        </div>
                    )}
                </>
            )}

            {validLicense?.status && validLicense?.message && (
                <div className="alert alert-warning mt-3" role="alert">
                    <p className="mb-0">{validLicense?.message}</p>
                </div>
            )}

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    ((_.isEmpty(_.get(data, `DemandeTarif[ListePersonnes][0][ListePermis]`)) &&
                        data?.noPermis !== '0' &&
                        data?.noPermis == null) ||
                        isNotValid() ||
                        !validLicense?.status) &&
                    'disabled'
                }`}
                type={'submit'}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
        </Question>
    );
};

export default ChoixPermis;
