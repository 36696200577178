import {createSelector} from "reselect";

const dejaClientSelector = state => state.dejaClient
const dejaClientListePersonnesSelector = state => state.dejaClient?.DemandeTarif?.ListePersonnes

export const getDejaClient = createSelector(
    dejaClientSelector,
    (dejaClient) => dejaClient,
);

export const getListePersonnes0DejaClient = createSelector(
    dejaClientListePersonnesSelector,
    (dejaClientLP) => dejaClientLP?.[0],
);

export const getListePersonnes0Adresse1DejaClient = createSelector(
    getListePersonnes0DejaClient,
    (dejaClientLP0) => dejaClientLP0?.Adresse1,
);

export const getListePersonnes0Adresse2DejaClient = createSelector(
    getListePersonnes0DejaClient,
    (dejaClientLP0) => dejaClientLP0?.Adresse2,
);

export const getListePersonnes0Adresse3DejaClient = createSelector(
    getListePersonnes0DejaClient,
    (dejaClientLP0) => dejaClientLP0?.Adresse3,
);

export const getListePersonnes0CPDejaClient = createSelector(
    getListePersonnes0DejaClient,
    (dejaClientLP0) => dejaClientLP0?.CP,
);

export const getListePersonnes0VilleDejaClient = createSelector(
    getListePersonnes0DejaClient,
    (dejaClientLP0) => dejaClientLP0?.Ville,
);