import { API_URL_WS_FORMULAIRE } from '@amo/core/constants/constants.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormValues } from 'redux-form';
import { SAVE_DEVIS, postSaveDevis } from '../../../../../redux/actions/app/saveDevis/saveDevis.actions';
import { getToken } from '../../../../../redux/selectors/init/init.selectors';
import { getErrorEntity, getLoadingEntity, getStep } from '../../../../../redux/selectors/ui/ui.selectors';
import InformationsSouscripteur from './InformationsSouscripteur';
import {
    getDejaClient,
    getListePersonnes0DejaClient
} from "../../../../../redux/selectors/dejaClient/dejaClient.selectors.js";

const mapStateToProps = (state) => {
    return {
        saveDevisLoading: getLoadingEntity(state, SAVE_DEVIS),
        saveDevisError: getErrorEntity(state, SAVE_DEVIS),
        querySaveDevis: `${API_URL_WS_FORMULAIRE}/save_devis/${getToken(state)}`,
        data: getFormValues('devis-direct')(state),
        step: getStep(state)
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            postSaveDevis,
        },
        dispatch,
    );

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { data, step, querySaveDevis } = stateProps;
    const { postSaveDevis, touch, setSubmitFailed } = dispatchProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        postSaveDevis: () => postSaveDevis({ form: data, query: querySaveDevis, step }),
    };
};

const InformationsSouscripteurContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
)(InformationsSouscripteur);

export default InformationsSouscripteurContainer;
