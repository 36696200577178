const IbanDejaClient = () => {
    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-12 col-12 mb-2'}>
                <div className={'alert-info alert mb-0 mt-4'}>
                    Voici les coordonnées bancaires en notre possession et sur
                    lesquelles nous prélèverons vos cotisations pour votre nouveau véhicule.<br/>
                    Vous pouvez modifier vos coordonnées bancaires à tout moment via votre espace assuré.
                </div>
            </div>
        </div>
    );
};

export default IbanDejaClient;