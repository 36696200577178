import carte from '@amo/core/assets/images/AMO_Icons/Carte_Grise.svg';
import { Question } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import _ from 'lodash';
import { Field } from 'redux-form';

export const DeclarationAssurance = (props) => {
    const { nextQuestion, data } = props;

    return (
        <Question icon={carte} title={'Vous déclarez avoir été assuré de <b>manière permanente ou non pour un 2 roues 125cm³ ou un 3 roues de la catégorie L5e entre 2006 et 2010</b>&nbsp;?'}>
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[ListePersonnes][0][DeclarationAssurance]'}
                        value={'1'}
                        label={'Oui'}
                        onClick={() => nextQuestion(300)}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[ListePersonnes][0][DeclarationAssurance]'}
                        value={'0'}
                        label={'Non'}
                    />
                </div>
            </div>

            {_.get(data, 'DemandeTarif[ListePersonnes][0][DeclarationAssurance]') === '0' && (
                <div className="alert alert-danger mt-3 col-lg-8 offset-lg-2" role="alert">
                    <b>Vous pouvez conduire une moto 125cm³ avec un permis B si vous remplissez les 2 conditions
                        suivantes :</b><br/>
                    - Vous avez le permis B depuis au moins 2 ans<br/>
                    - Vous avez suivi une formation pratique de 7 heures<br/><br/>
                    <b>Vous pouvez conduire un scooter 3 roues de la catégorie L5e avec un permis B si vous remplissez
                        les 3 conditions
                        suivantes :</b><br/>
                    - Vous avez le permis B depuis au moins 2 ans<br/>
                    - Vous avez 21 ans ou plus<br/>
                    - Vous avez suivi une formation pratique de 7 heures<br/><br/>
                    <b>Vous êtes dispensé de suivre la formation dans les 3 cas suivants :</b><br/>
                    - Vous avez obtenu votre permis B (avec l'équivalence A1) avant mars 1980<br/>
                    - Vous avez conduit une 125cm³ ou un scooter 3 roues de la catégorie L5e entre 2006 et 2010<br/>
                    - Si vous avez suivi la formation de 3 heures avant 2011
                </div>
            )}

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    _.get(data, 'DemandeTarif[ListePersonnes][0][DeclarationAssurance]') !== '1' && 'disabled'
                }`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
        </Question>
    );
};
