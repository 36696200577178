import { PostalCodeCity, CAPAdresse } from '@amo/core/components/forms';
import { excludeCodePostal } from '@amo/core/utils/validateField';
import _ from 'lodash';

const Adresse = ({ data, changeValue, setHasSearchAddress, isDejaClient }) => {
    return (
        <div>
            <div className="row justify-content-center">
                <div className="col-lg-8 col-md-6 col-sm-6 col-12 mt-2">
                    { isDejaClient &&
                        <p className={'f-14 text-secondary text-start fw-bold mt-2 mb-2'}>
                            Mon adresse postale
                        </p>
                    }

                    <PostalCodeCity
                        label="Code postal & Ville"
                        required
                        id={isDejaClient ? 'dejaclientcodepostal' : 'codepostal'}
                        nameVille={isDejaClient ? 'DejaClient[Ville]' : 'DemandeTarif[ListePersonnes][0][Ville]'}
                        nameCodePostal={isDejaClient ? 'DejaClient[CP]' : 'DemandeTarif[ListePersonnes][0][CP]'}
                        validate={excludeCodePostal}
                        placeholder="Exemple : 75011 PARIS"
                        changeValue={changeValue}
                        disabled={isDejaClient && _.get(data, 'DejaClient[Validate]') !== false}
                        defaultValue={_.get(data, 'DemandeTarif[ListePersonnes][0][CP]') &&
                            `${_.get(data, 'DemandeTarif[ListePersonnes][0][CP]')} ${_.get(data, 'DemandeTarif[ListePersonnes][0][Ville]')}`}
                    />
                </div>

                <div className="row justify-content-center">
                    <CAPAdresse
                        disabled={isDejaClient && _.get(data, 'DejaClient[Validate]') !== false}
                        changeValue={changeValue}
                        setHasSearchAddress={setHasSearchAddress}
                        data={data}
                        path={isDejaClient ? 'DejaClient' : 'DemandeTarif[ListePersonnes][0]'}
                    />
                </div>
            </div>
        </div>
    );
};

export default Adresse;
