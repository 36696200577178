import {SET_DEJA_CLIENT} from "../../actions/app/dejaClient/dejaClient.actions.js";

export const dejaClientReducer = (dejaClient = {}, action) => {
    const {payload} = action

    switch (action.type) {
        case SET_DEJA_CLIENT:
            return payload.data

        default:
            return dejaClient

    }
}
