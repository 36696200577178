import tchat from '@amo/core/assets/images/AMO_Icons/Service-client.svg';
import { Question } from '@amo/core/components/containers';
import { Checkbox, Text } from '@amo/core/components/forms';
import { normalizeTel } from '@amo/core/utils/normalize.js';
import { postLogConsent } from '@amo/core/utils/requestsApi';
import { validatePhone } from '@amo/core/utils/validateField';
import _ from 'lodash';
import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Field } from 'redux-form';

const NumeroTel = (props) => {
    const { postFill, data, changeValue, nameWhitelabel, loadingPostFill, needCaptcha, token } = props;
    const captchaRef = useRef(null);
    const [validCaptcha, setValidCaptcha] = useState(false);

    const checkCaptcha = () => {
        const token = captchaRef.current.getValue();
        setValidCaptcha(needCaptcha && token);
    };

    async function logConsent(token, phone) {
        await postLogConsent(token, phone);
    }

    const onClickNext = () => {
        postFill({ getTarif: true });
        logConsent({ token: token, phone: _.get(data, 'DemandeTarif[ListePersonnes][0][TelPortable]') });
    };

    return (
        <Question icon={tchat} title={'Quel est votre <b>numéro de téléphone</b>&nbsp;?'}>
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-md-6 col-12 my-2'}>
                    <Field
                        component={Text}
                        type={'tel'}
                        typeFormat={'tel'}
                        name={'DemandeTarif[ListePersonnes][0][TelPortable]'}
                        placeholder={'Exemple : 02 38 53 02 38'}
                        validate={validatePhone}
                        onChange={(e) => changeValue('DemandeContrat[SignaturePhone]', normalizeTel(e.target.value))}
                    />
                </div>
            </div>

            <div className={'row mt-3'}>
                <div className={'col-12 col-lg-6 offset-lg-3'}>
                    <Field name="accept-recontact" component={Checkbox}>
                        <p>
                            Afin d'être accompagné dans le cadre de ma démarche d'assurance, j'accepte qu’un expert moto
                            me contacte par téléphone et/ou email.
                        </p>
                        <small>
                            (Pour vous conseiller au mieux dans le cadre de votre démarche, vous pourrez être rappelé
                            par un expert {nameWhitelabel === 'APRIL Moto' ? 'APRIL Moto' : 'AM Gestion'} d'ADM Value
                            -N°ORIAS : 10055724-)
                        </small>
                        <p>
                            <sup className={'text-success'}>Obligatoire</sup>
                        </p>
                    </Field>
                </div>
            </div>
            
            {needCaptcha  && (
                <ReCAPTCHA
                    sitekey={'6Lc6nbofAAAAADq-aOT5XLxMUBr-jrlaWZe5YMqF'}
                    className={'m-auto mt-3'}
                    style={{ maxWidth: 300 }}
                    ref={captchaRef}
                    onChange={checkCaptcha}
                />
            )}

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    !_.get(data, 'DemandeTarif[ListePersonnes][0][TelPortable]') ||
                    validatePhone(_.get(data, 'DemandeTarif[ListePersonnes][0][TelPortable]')) ||
                    !_.get(data, 'accept-recontact') ||
                    (needCaptcha && !validCaptcha)
                        ? 'disabled'
                        : ''
                }`}
                onClick={() => (loadingPostFill ? null : onClickNext())}
            >
                {loadingPostFill ? (
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                ) : (
                    'Comparer les tarifs'
                )}
            </button>
        </Question>
    );
};

export default NumeroTel;
